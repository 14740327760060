<template>
  <router-view v-if="subPage"></router-view>
  <CRow v-else>
    <CCol md="12" v-if="markets_loading">
      <div class="loading text-center">
        <CSpinner color="primary" size="lg" />
      </div>
    </CCol>
    <CCol md="6" v-for="(market, index) in markets" :key="index">
      <MarketsBox v-model="markets[index]" />
    </CCol>
  </CRow>
</template>

<script>
import { GetDataManager, ApiCall } from "../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import MarketsBox from "./MarketsBox";
export default {
  data() {
    return {
      markets: [],
      markets_loading: false,
    };
  },
  computed: {
    subPage() {
      return this.$route.params.id;
    },
  },
  methods: {
    UpdateMarkets() {
      if (!this.subPage) {
        let self = this;
        self.markets_loading = true;
        console.log("Update markets");
        ApiCall(GetDataManager("auth_markets"), new Query(), function (e) {
          self.numPages = Math.ceil(e.actual.Count / 12);
          self.markets = e.result;
          self.markets_loading = false;
        });
      }
    },
  },
  created() {
    this.UpdateMarkets();
  },
  //   name: "ProfileData",
  components: {
    MarketsBox,
  },
};
</script>
